import pkg from '../../package.json';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { Inertia } from '@inertiajs/inertia';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import LazyLoadDirective from '@/Directives/LazyLoadDirective';
import SubdomainLink from '@/Components/SubdomainLink';
import { Workbox } from 'workbox-window';

const el = document.getElementById('app');
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Zoundy';

createInertiaApp({
	title: (title) => {
		return `${title} - ${appName}`;
	},
	resolve: (name) => import(`./Pages/${name}.vue`),
	setup({ el, app, props, plugin }) {
		let createdApp = createApp({ render: () => h(app, props) });

		const getSvgHtml = require.context('../svgs/', true, /\.svg$/);
		// Configure and Mount Vue App
		if (process.env.NODE_ENV === 'production') {
			Bugsnag.start({
				apiKey: '4b8cd91b2a071bbabec9216fe64c2d6a',
				plugins: [new BugsnagPluginVue()],
				appVersion: `${pkg.version}`,
			});

			createdApp.use(Bugsnag.getPlugin('vue'));

			setTimeout(() => {
				Bugsnag.notify(new Error('Test error'));
				console.log('Testing error complete');
			}, 3000);
		}

		return createdApp
			.use(plugin)
			.component('subdomain-link', SubdomainLink)
			.directive('lazyload', LazyLoadDirective)
			.mixin({
				methods: {
					route,
					$getSvgHtml: (name) => {
						return getSvgHtml(`./${name}.svg`);
					},
					$getIconHtml: (name, lib = null) => {
						let path = lib ? `./icons/${lib}/${name}.svg` : `./icons/${name}.svg`;
						return getSvgHtml(path);
					},
				},
			})
			.mount(el);
	},
});

InertiaProgress.init({ color: '#2f61e9' });

// Service Worker
if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		const wb = new Workbox(`${window.location.origin}/service-worker.js`);
		wb.register();
	});
}

// Handle inertia errors
if (process.env.NODE_ENV === 'production') {
	let justLoaded = new Date().getTime();
	Inertia.on('invalid', (event) => {
		event.preventDefault();

		if (event?.isTrusted !== false) Bugsnag.notify(event);
		console.error(event);

		setTimeout(() => {
			if (new Date().getTime() - justLoaded > 1000) {
				document.location.reload(true);
			}
		}, 500);
	});
}
